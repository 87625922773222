import React, { FunctionComponent, useContext } from "react";
import { TestEventContext } from "../../../../Contexts/TestEventContext";
import { useShowChat } from "../../../../CustomHooks/useShowChat";
import { LanguageContext } from "../../../../Providers/LanguageProvider";
import NetworkErrorIcon from "../../../icons/network-error";

import styles from "./ExpiredTimeError.module.css";

const DEFAULT_MESSAGE =
  "There was a problem starting your test. Please contact our Candidate Support Specialists via the chat function in the bottom right corner of the testing center window.";

type ExpiredTimeErrorProps = {
  message: string;
};

const ExpiredTimeError: FunctionComponent<ExpiredTimeErrorProps> = ({
  message
}) => {
  const TestEvent = useContext(TestEventContext);
  const language = useContext(LanguageContext)["error"];
  useShowChat();

  TestEvent.errorIsShowing = true;

  return (
    <article className={styles.container}>
      <section className={styles.content}>
        <NetworkErrorIcon />
        <h1 className={styles["top-text"]}>{language.common.UH_OH}</h1>
        <p className={styles["bottom-text"]}>{message ?? DEFAULT_MESSAGE}</p>
      </section>
    </article>
  );
};

export default ExpiredTimeError;
