import { useContext, useEffect, useState } from "react";
import { TestEventContext } from "../Contexts/TestEventContext";
import { Hook } from "../utils/Reference/Hook";

type VisibilityResult = {
  isHidden: boolean;
  refocus: () => void;
};

const useVisibility: Hook<void, VisibilityResult> = () => {
  const TestEvent = useContext(TestEventContext);
  const [isHidden, setIsHidden] = useState(TestEvent.hideOnInit);

  useEffect(() => {
    const visibilityChangeHandler = () => {
      if (document.visibilityState === "hidden") {
        setIsHidden(TestEvent.useLockout && !TestEvent.errorIsShowing);
      }
    };
    const focusLossHandler = () => {
      setIsHidden(TestEvent.useLockout && !TestEvent.errorIsShowing);
    };
    window.addEventListener("visibilitychange", visibilityChangeHandler);
    window.addEventListener("blur", focusLossHandler);
    return () => {
      window.removeEventListener("visibilitychange", visibilityChangeHandler);
      window.removeEventListener("blur", focusLossHandler);
    };
  }, [TestEvent]);

  return {
    isHidden,
    refocus: () => {
      TestEvent.hideOnInit = false;
      setIsHidden(false);
    }
  };
};

export default useVisibility;
