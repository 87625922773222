import React, { FunctionComponent, useContext } from "react";
import { TestEventContext } from "../../../Contexts/TestEventContext";
import useVisibility from "../../../CustomHooks/useVisibility";
import { TokenContext } from "../../../Providers/TokenProvider";
import LockoutMessage from "./LockoutMessage";

const LockoutController: FunctionComponent = ({ children }) => {
  const { returnUrl } = useContext(TokenContext);
  const TestEvent = useContext(TestEventContext);
  const { isHidden, refocus, lockout } = useVisibility();

  if (isHidden && lockout.isLockout) {
    const lockoutLocation = new URL(returnUrl);
    window.location.replace(lockoutLocation.origin + lockoutLocation.pathname);
  }

  return isHidden && TestEvent.useLockout ? (
    <LockoutMessage lockout={lockout} refocus={refocus} />
  ) : (
    children
  );
};

export default LockoutController;
