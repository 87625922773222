/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Row, Col, Alert, Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import {
  browserVersion,
  browserName,
  osName,
  osVersion,
  isMobile
} from "react-device-detect";
import { FadeLoader } from "react-spinners";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import { format } from "date-fns";
import {
  GDPRCountryValues,
  validateCandidateInput,
  getTranslatedTextWithURL,
  phoneCountryCodes,
  formatPhoneNumber
} from "../utils/shared";
import Field from "./Field";
import { TestEventConsumer } from "../Contexts/TestEventContext";
import TestTakerData from "../Interfaces/TestTakerData";
import {
  getDropdownOptions,
  getTestTakerPhoneNumberValidationText,
  isTestTakerPhoneNumberValidated
} from "./helpers";
import { FPKey } from "../utils/constants";
import PhoneNumberField from "./PhoneNumberField";
import { isCustomTermsAndConditionsStyles } from "../utils/customConfig";

/* ---------- Interfaces ---------- */

interface Props {
  jobCode?: string | null;
  testData: any;
  referrer?: {
    id: number | null;
    url: string;
  };
}
interface State {
  testTakerFirstName: string | null;
  testTakerLastName: string | null;
  testTakerEmail: string | null;
  testTakerPhoneNumber: string | null;
  testTakerPhoneCountryCode: string;
  testTakerLinkedIn: string;
  country: string;
  testTakerEthnicity: string;
  testTakerBirthYear: string;
  testTakerEducation: string;
  testTakerEnglish: string;
  testTakerGender: string;
  testTakerIndustry: string;
  jobCode: string;
  agreeToTerms: boolean;
  hasErrors: boolean;
  noPII: boolean;
  browser: string;
  version: string;
  platform: string;
  isMobile: boolean;
  testTakerSubmitError: string;
  validationStates: ValidationObject;
}
interface ValidationObject {
  testTakerFirstName: string | null;
  testTakerEmail: string | null;
  testTakerPhoneNumber: string | null;
  country: string | null;
  testTakerLinkedIn: string | null;
}

export default class TestTakerForm extends Component<Props> {
  // Declare a static contextType so that we can use this.context throughout the class.
  static contextType: any = TestEventConsumer;
  state: State = {
    testTakerFirstName: null,
    testTakerLastName: null,
    testTakerEmail: null,
    testTakerPhoneNumber: null,
    testTakerPhoneCountryCode: "US",
    testTakerLinkedIn: "",
    country: "",
    testTakerEthnicity: "",
    testTakerBirthYear: "",
    testTakerEducation: "",
    testTakerEnglish: "",
    testTakerGender: "",
    testTakerIndustry: "",
    jobCode: this.context.testEventData.jobCode,
    agreeToTerms: false,
    hasErrors: false,
    noPII: false,
    browser: "",
    version: "",
    platform: "",
    isMobile: false,
    testTakerSubmitError: "",
    validationStates: {
      testTakerFirstName: null,
      testTakerEmail: null,
      testTakerPhoneNumber: null,
      country: null,
      testTakerLinkedIn: null
    }
  };

  componentDidMount() {
    const testTakerFirstName =
      this.context.first !== "" &&
      this.context.first !== null &&
      this.context.first !== undefined
        ? this.context.first
        : "";
    const testTakerLastName =
      this.context.last !== "" &&
      this.context.last !== null &&
      this.context.last !== undefined
        ? this.context.last
        : "";
    const testTakerEmail =
      this.context.email !== "" &&
      this.context.email !== null &&
      this.context.email !== undefined
        ? this.context.email
        : this.state.testTakerEmail;
    const country =
      this.props.testData && this.props.testData.testTaker
        ? this.props.testData.testTaker.testTakerState
        : this.state.country;
    const testTakerEthnicity = this.context.testTakerEthnicity || null;
    const testTakerBirthYear = this.context.testTakerBirthYear || null;
    const testTakerEducation = this.context.testTakerEducation || null;
    const testTakerEnglish = this.context.testTakerEnglish || null;
    const testTakerGender = this.context.testTakerGender || null;
    const testTakerIndustry = this.context.testTakerIndustry || null;
    const linkedInProfile = this.context.linkedInProfile || "";

    this.setState({
      testTakerFirstName,
      testTakerLastName,
      testTakerEmail,
      country,
      testTakerEthnicity,
      testTakerBirthYear,
      testTakerEducation,
      testTakerEnglish,
      testTakerGender,
      testTakerIndustry,
      linkedInProfile
    });

    // determine if the testEvent is from a no-PII account
    let noPII = false;
    if (this.context.testEventData.anonymous) {
      if (this.context.eventId !== "") {
        noPII = true;
      }
    }
    this.setState({ noPII });

    // get candidate metadata
    const platform = `${osName} v.${osVersion}`;
    this.setState({
      browser: browserName,
      version: browserVersion,
      platform,
      isMobile
    });
    this.context.updateRetrievingData(false);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.testTakerFirstName === null &&
      prevProps.testData.testTaker &&
      prevProps.testData.testTaker.testTakerFirstName
    ) {
      this.setState({
        testTakerFirstName: prevProps.testData.testTaker.testTakerFirstName
      });
    }
    if (
      prevState.testTakerLastName === null &&
      prevProps.testData.testTaker &&
      prevProps.testData.testTaker.testTakerLastName
    ) {
      this.setState({
        testTakerLastName: prevProps.testData.testTaker.testTakerLastName
      });
    }
    if (
      prevState.testTakerEmail === null &&
      prevProps.testData.testTaker &&
      prevProps.testData.testTaker.testTakerEmail
    ) {
      this.setState({
        testTakerEmail: prevProps.testData.testTaker.testTakerEmail
      });
    }
    if (
      prevState.testTakerPhoneNumber === null &&
      prevProps.testData.testTaker &&
      prevProps.testData.testTaker.testTakerPhoneNumber
    ) {
      this.setState({
        testTakerPhoneNumber: prevProps.testData.testTaker.testTakerPhoneNumber
      });
    }
    if (
      prevState.testTakerState === null &&
      prevProps.testData.testTaker &&
      prevProps.testData.testTaker.testTakerState
    ) {
      this.setState({
        testTakerState: prevProps.testData.testTaker.testTakerState
      });
    }
    if (
      prevState.testTakerEthnicity === null &&
      prevProps.testData.testTaker &&
      prevProps.testData.testTaker.testTakerEthnicity
    ) {
      this.setState({
        testTakerEthnicity: prevProps.testData.testTaker.testTakerEthnicity
      });
    }
    if (
      prevState.testTakerBirthYear === null &&
      prevProps.testData.testTaker &&
      prevProps.testData.testTaker.testTakerBirthYear
    ) {
      this.setState({
        testTakerBirthYear: prevProps.testData.testTaker.testTakerBirthYear
      });
    }
    if (
      prevState.testTakerEducation === null &&
      prevProps.testData.testTaker &&
      prevProps.testData.testTaker.testTakerEducation
    ) {
      this.setState({
        testTakerEducation: prevProps.testData.testTaker.testTakerEducation
      });
    }
    if (
      prevState.testTakerEnglish === null &&
      prevProps.testData.testTaker &&
      prevProps.testData.testTaker.testTakerEnglish
    ) {
      this.setState({
        testTakerEnglish: prevProps.testData.testTaker.testTakerEnglish
      });
    }
    if (
      prevState.testTakerGender === null &&
      prevProps.testData.testTaker &&
      prevProps.testData.testTaker.testTakerGender
    ) {
      this.setState({
        testTakerGender: prevProps.testData.testTaker.testTakerGender
      });
    }
    if (
      prevState.testTakerIndustry === null &&
      prevProps.testData.testTaker &&
      prevProps.testData.testTaker.testTakerIndustry
    ) {
      this.setState({
        testTakerIndustry: prevProps.testData.testTaker.testTakerIndustry
      });
    }
  }

  /* ---------- Event Handlers ---------- */

  handleInput = <T extends keyof State>(
    event: React.SyntheticEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    // Define the target value
    const target = event.currentTarget;
    const value = target.value;

    // check if incoming event is from a checkbox. If so, set state differently.
    if (target.type === "checkbox") {
      this.setState(state => {
        return { agreeToTerms: !this.state.agreeToTerms };
      });
    } else if (target.id === "testTakerPhoneNumber") {
      const stateKey: string = target.id;

      const formattedPhoneNumber = formatPhoneNumber(
        value,
        phoneCountryCodes[this.state.testTakerPhoneCountryCode]
      );

      // Set a new state object, with the new input data added
      const newState = {
        [stateKey]: formattedPhoneNumber
      };

      // Set new state object into state
      this.setState(
        newState as {
          [P in T]: State[P];
        },
        () => {
          if (this.state.hasErrors) {
            this.getValidationStates();
          }
        }
      );
    } else {
      // dynamically assign the id of the incoming input to a variable to be used throughout function
      const stateKey: string = target.id;

      // Set a new state object, with the new input data added
      const newState = { [stateKey]: value };

      // Set new state object into state
      this.setState(newState as { [P in T]: State[P] }, () => {
        if (this.state.hasErrors) {
          this.getValidationStates();
        }
      });
    }
  };

  handleSubmit = async () => {
    if (this.getValidationStates()) {
      if (this.state.agreeToTerms) {
        this.context.updateRetrievingData(true);
        // initialize a variable to the testEventData on the context for readability purposes
        const testEventData: any = this.context.testEventData;

        /*
         * FingerprintJS operations
         */
        let visitorId: string | null = null;
        try {
          const fp = await FingerprintJS.load({
            token: FPKey,
            endpoint: `https://fp.ondemandassessment.com`
          });
          const fingerprintResult = await fp.get();
          visitorId = fingerprintResult.visitorId;
        } catch (error) {
          console.log("Error: ", error);
        }

        // if any of the subtests have a formId, attach that, with the subTestId, to the formData
        let formIdObject = {};

        if (testEventData.tests) {
          Object.keys(testEventData.tests).map(index => {
            if (testEventData.tests[index].details.formId) {
              formIdObject = {
                ...formIdObject,
                [index]: testEventData.tests[index].details.formId
              };
            }
          });
        }

        const showPhoneNumberField =
          this.props.testData?.company?.smsReminderForOpenLinks;

        // request is expecting phone country code to be the code not the country abbreviation (e.g. +1)
        const newCandidatePhoneCountryCode =
          phoneCountryCodes[this.state.testTakerPhoneCountryCode];

        // request is expecting the phone number to be stripped of the country code, spaces, and non-digits
        const newCandidatePhoneNumber = this.state.testTakerPhoneNumber
          ? this.state.testTakerPhoneNumber
              .replace(`${newCandidatePhoneCountryCode} `, "")
              .replace(/\D/g, "")
          : null;

        // initialize a data variable with all of the test taker's demographic data
        let data: TestTakerData = {
          testTakerFirstName: this.state.testTakerFirstName,
          testTakerLastName: this.state.testTakerLastName,
          testTakerEmail: this.state.testTakerEmail,
          testTakerState: this.state.country,
          testTakerEthnicity: this.state.testTakerEthnicity,
          testTakerBirthYear: this.state.testTakerBirthYear,
          testTakerEducation: this.state.testTakerEducation,
          testTakerEnglish: this.state.testTakerEnglish,
          testTakerGender: this.state.testTakerGender,
          testTakerIndustry: this.state.testTakerIndustry,
          linkedInProfile: this.state.testTakerLinkedIn,
          browser: this.state.browser,
          version: this.state.version,
          platform: this.state.platform,
          isMobile: this.state.isMobile,
          formId: formIdObject,
          u: this.context.u,
          orderId: this.context.orderId,
          source: this.context.source,
          visitorId: visitorId,
          ...(showPhoneNumberField && {
            phoneNumber: newCandidatePhoneNumber
          }),
          ...(showPhoneNumberField && {
            phoneCountryCode: newCandidatePhoneNumber
              ? newCandidatePhoneCountryCode
              : null
          })
        };

        // conditionally define a method variable and a url variable dependent on whether the user got
        // to this form from entering aneventId or following a link
        const method: string = testEventData.testTaker ? "PUT" : "POST";
        const requestUrl = testEventData.testTaker
          ? `/testTaker/${testEventData.testTaker.testTakerId}`
          : `/testTaker`;

        // if the user reached the form via a link, append the job code from the link to the test taker data
        if (!testEventData.testTaker) {
          data = {
            ...data,
            jobCode:
              this.context.jobCode || this.state.jobCode || this.props.jobCode
          };
        }
        this.context
          .sendTestTakerData(requestUrl, method, data, this.props.referrer)
          .catch((error: Error) => {
            this.context.updateRetrievingData(false);
            this.setState({
              testTakerSubmitError: getTranslatedTextWithURL(
                this.context,
                "errorBeginning"
              )
            });
          });
      } else {
        alert(
          "You must accept and agree to the Terms of Use and Privacy Statement to continue."
        );
        this.animateScroll();
      }
    } else {
      this.animateScroll();
    }
  };

  animateScroll = () => {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  };

  /* ---------- Validator Function ---------- */

  getValidationStates = () => {
    const translatedTestTakerFormErrors =
      this.context.testEventData &&
      this.context.testEventData.translatedText &&
      this.context.testEventData.translatedText.testTakerFormErrors
        ? JSON.parse(
            this.context.testEventData.translatedText.testTakerFormErrors
          )
        : null;
    this.setState({
      validationStates: {
        testTakerFirstName:
          (this.state.testTakerFirstName &&
            this.state.testTakerFirstName.length > 0) ||
          this.state.noPII
            ? null
            : translatedTestTakerFormErrors &&
              translatedTestTakerFormErrors.firstName
            ? translatedTestTakerFormErrors.firstName
            : "The First Name field is required.",
        testTakerEmail:
          this.state.noPII ||
          (this.state.testTakerEmail &&
            this.state.testTakerEmail.length > 0 &&
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.testTakerEmail))
            ? null
            : translatedTestTakerFormErrors &&
              translatedTestTakerFormErrors.emailAddress
            ? translatedTestTakerFormErrors.emailAddress
            : "A valid email address is required.",
        testTakerPhoneNumber: getTestTakerPhoneNumberValidationText(
          this.state.noPII,
          this.state.testTakerPhoneNumber
        ),
        country:
          this.state.country || this.state.noPII
            ? null
            : translatedTestTakerFormErrors &&
              translatedTestTakerFormErrors.country
            ? translatedTestTakerFormErrors.country
            : "A valid country of residence is required.",
        testTakerLinkedIn:
          this.state.testTakerLinkedIn === null ||
          this.state.testTakerLinkedIn.length === 0 ||
          validateCandidateInput(
            this.state.testTakerLinkedIn.toLowerCase(),
            "linkedin.com/",
            100
          )
            ? null
            : translatedTestTakerFormErrors &&
              translatedTestTakerFormErrors.linkedIn
            ? translatedTestTakerFormErrors.linkedIn
            : "Please enter a valid LinkedIn url."
      }
    });
    if (
      this.state.noPII ||
      (this.state.testTakerFirstName &&
        this.state.testTakerFirstName.length > 0 &&
        this.state.testTakerEmail &&
        this.state.testTakerEmail.length > 0 &&
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.testTakerEmail) &&
        this.state.country?.length > 0 &&
        (this.state.testTakerLinkedIn === null ||
          this.state.testTakerLinkedIn.length === 0 ||
          validateCandidateInput(
            this.state.testTakerLinkedIn.toLowerCase(),
            "linkedin.com/",
            100
          )))
    ) {
      if (this.state.hasErrors) {
        this.setState({ hasErrors: false });
      }

      if (
        isTestTakerPhoneNumberValidated(
          this.state.noPII,
          this.state.testTakerPhoneNumber
        )
      ) {
        return true;
      }

      return false;
    } else {
      this.setState({ hasErrors: true });
      return false;
    }
  };

  handlePhoneCountryCodeDropdown = (value: string) => {
    this.setState({ testTakerPhoneCountryCode: value });
  };

  render() {
    const dropdown = this.context.testEventData.translatedText
      ? JSON.parse(this.context.testEventData.translatedText.dropdown)
      : null;

    // ethnicity dropdown options are based off of country selected in verify form
    let ethnicityBasedOnCountryOptions: any = dropdown.Race[0]["US"];

    if (dropdown != null && this.state.country !== "US") {
      dropdown.Race.map((ethnicityCountry: any) => {
        if (Object.keys(ethnicityCountry).includes(this.state.country)) {
          ethnicityBasedOnCountryOptions = ethnicityCountry[this.state.country];
        }
      });
    }

    // age dropdown has year of birth options 1930 to current year
    const yearOptions: string[] = [];
    const currentYear: number = Number(format(new Date(), "yyyy"));
    for (let year: number = 1930; year <= currentYear; year++) {
      yearOptions.push(year.toString());
    }
    const ageDropdownOptions: any = yearOptions.map((ageOption: string) => {
      return (
        <option key={ageOption} value={ageOption}>
          {ageOption}
        </option>
      );
    });

    const ethnicityOptions = getDropdownOptions(ethnicityBasedOnCountryOptions);
    const genderOptions = getDropdownOptions(dropdown.Gender);
    const educationOptions = getDropdownOptions(dropdown.Education);
    const languageOptions = getDropdownOptions(dropdown.language);
    const countryOptions = getDropdownOptions(dropdown.Country);
    const industryOptions = getDropdownOptions(dropdown.Industries);

    // show phone number input field if smsReminderForOpenLinks is true and candidate has been directed from open link (testTaker data does not exist)
    const showPhoneNumberField =
      this.props.testData?.company?.smsReminderForOpenLinks &&
      !this.props.testData?.testTaker;

    const isArabic = this.context?.testEventData?.translatedText?.locale
      ? this.context.testEventData.translatedText?.locale === "AR"
      : null;

    if (!this.context.retrievingData) {
      return (
        <div
          className={isArabic ? "text-right" : ""}
          dir={isArabic ? "rtl" : "ltr"}
        >
          {this.state.testTakerSubmitError ? (
            <Alert variant="danger">{this.state.testTakerSubmitError}</Alert>
          ) : null}
          {!this.state.noPII ? (
            <p>
              {this.context.testEventData.translatedText
                ? this.context.testEventData.translatedText.pleaseVerify
                : "Please verify or enter your personal information below."}{" "}
              {this.context.testEventData.translatedText
                ? this.context.testEventData.translatedText.uniqueEmail
                : null}
            </p>
          ) : null}
          {this.state.hasErrors ? (
            <Alert variant="danger" role="alert">
              <ul>
                {this.state.validationStates.testTakerFirstName !== null ? (
                  <li>{this.state.validationStates.testTakerFirstName}</li>
                ) : null}
                {this.state.validationStates.testTakerEmail !== null ? (
                  <li>{this.state.validationStates.testTakerEmail}</li>
                ) : null}
                {this.state.validationStates.country !== null ? (
                  <li>{this.state.validationStates.country}</li>
                ) : null}
                {this.state.validationStates.testTakerLinkedIn !== null ? (
                  <li>{this.state.validationStates.testTakerLinkedIn}</li>
                ) : null}
              </ul>
            </Alert>
          ) : null}
          {this.context.invalidTestTakerMessage !== "" ? (
            <Alert variant="danger">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.context.invalidTestTakerMessage
                }}
              />
            </Alert>
          ) : null}
          <Row>
            {!this.state.noPII ? (
              <Col>
                <Field
                  id="testTakerFirstName"
                  fieldType="textbox"
                  label={
                    this.context.testEventData.translatedText
                      ? this.context.testEventData.translatedText.firstName
                      : "First Name"
                  }
                  value={this.state.testTakerFirstName}
                  handleInput={this.handleInput}
                  maxLength={32}
                  fieldRequired={true}
                />
                <Field
                  id="testTakerLastName"
                  fieldType="textbox"
                  label={
                    this.context.testEventData.translatedText
                      ? this.context.testEventData.translatedText.lastName
                      : "Last Name"
                  }
                  value={this.state.testTakerLastName}
                  handleInput={this.handleInput}
                  maxLength={32}
                />
                <Field
                  id="testTakerEmail"
                  fieldType="email"
                  label={
                    this.context.testEventData.translatedText
                      ? this.context.testEventData.translatedText.email
                      : "Email Address"
                  }
                  value={this.state.testTakerEmail}
                  handleInput={this.handleInput}
                  maxLength={50}
                  disabled={this.context.testEventData.isTeamingEvent}
                  fieldRequired={true}
                />
                {showPhoneNumberField ? (
                  <>
                    <PhoneNumberField
                      id="testTakerPhoneNumber"
                      label={
                        this.context.testEventData.translatedText?.phoneNumber
                          ? this.context.testEventData.translatedText
                              .phoneNumber
                          : "Mobile Phone Number (Optional):"
                      }
                      value={this.state.testTakerPhoneNumber}
                      handleInput={this.handleInput}
                      placeholder={`${
                        phoneCountryCodes[this.state.testTakerPhoneCountryCode]
                      } xxx-xxx-xxxx`}
                      fieldRequired={false}
                      handlePhoneCountryCodeDropdown={
                        this.handlePhoneCountryCodeDropdown
                      }
                      testTakerPhoneCountryCode={
                        this.state.testTakerPhoneCountryCode
                      }
                      validated={
                        this.state.validationStates.testTakerPhoneNumber
                      }
                    />
                    <p id="helper-text" className="field-helper-text mt-2 mb-3">
                      Enter your phone number to receive reminders about your
                      assigned tasks. Message and data rates may apply. Message
                      frequency varies.
                    </p>
                  </>
                ) : null}
                {this.context.testEventData.requireLinkedIn === 2 ? (
                  <Field
                    id="testTakerLinkedIn"
                    fieldType="textbox"
                    label={
                      this.context.testEventData.translatedText
                        ? this.context.testEventData.translatedText
                            .linkedInProfile
                        : "LinkedIn Profile (optional)"
                    }
                    value={this.state.testTakerLinkedIn}
                    handleInput={this.handleInput}
                    maxLength={100}
                  />
                ) : null}
              </Col>
            ) : null}
          </Row>
          {!this.state.noPII ? (
            <React.Fragment>
              <Field
                id="country"
                fieldType="dropdown"
                addSelectOneOption
                label={
                  this.context.testEventData.translatedText
                    ? this.context.testEventData.translatedText.residenceCountry
                    : "Location of Residence:"
                }
                value={this.state.country}
                options={countryOptions}
                handleInput={this.handleInput}
                fieldRequired={true}
              />
              <p className="field-helper-text mb-1">
                {this.context.testEventData.translatedText
                  ? this.context.testEventData.translatedText.ensureCompliance
                  : "This is to ensure compliance with applicable non-discriminatory and privacy laws."}
              </p>
            </React.Fragment>
          ) : null}
          <br />
          {this.context.testEventData.showDataQuestions && !this.state.noPII ? (
            <div id="demographic-questions-container" style={{ color: "#000" }}>
              <span className="location-text">
                {this.context.testEventData.translatedText
                  ? this.context.testEventData.translatedText.optional
                  : "Optional"}
              </span>
              <p id="optional-text">
                {this.context.testEventData.translatedText
                  ? this.context.testEventData.translatedText.personalInfo
                  : "In order to ensure that our tests are fair and non-discriminatory, we collect demographic information on test-takers. Submitting this information is entirely optional, and the information you enter here will not be shared with a prospective employer, other than anonymously and in aggregate form. A prospective employer will never know if you do or do not provide any of this information; it has no effect on your chances of being hired."}
              </p>
              <hr aria-hidden="true" id="optional-divider" />

              {this.state.country === "US" ||
              this.state.country === "GB" ||
              this.state.country === "AU" ? (
                <Field
                  label={
                    this.context.testEventData.translatedText
                      ? this.context.testEventData.translatedText.race
                      : "Race/Ethnicity"
                  }
                  id="testTakerEthnicity"
                  fieldType="dropdown"
                  addSelectOneOption
                  value={this.state.testTakerEthnicity}
                  options={ethnicityOptions}
                  handleInput={this.handleInput}
                />
              ) : null}
              <Field
                label={
                  this.context.testEventData.translatedText
                    ? this.context.testEventData.translatedText.yearOfBirth
                    : "Year of Birth"
                }
                id="testTakerBirthYear"
                fieldType="dropdown"
                addSelectOneOption
                value={this.state.testTakerBirthYear}
                options={ageDropdownOptions}
                handleInput={this.handleInput}
              />
              {!GDPRCountryValues.includes(this.state.country) ? (
                <Field
                  label={
                    this.context.testEventData.translatedText
                      ? this.context.testEventData.translatedText.gender
                      : "Gender"
                  }
                  id="testTakerGender"
                  fieldType="dropdown"
                  addSelectOneOption
                  value={this.state.testTakerGender}
                  options={genderOptions}
                  handleInput={this.handleInput}
                />
              ) : null}
              <Field
                label={
                  this.context.testEventData.translatedText
                    ? this.context.testEventData.translatedText.education
                    : "Education"
                }
                id="testTakerEducation"
                fieldType="dropdown"
                addSelectOneOption
                value={this.state.testTakerEducation}
                options={educationOptions}
                handleInput={this.handleInput}
              />
              <Field
                label={
                  this.context.testEventData.translatedText
                    ? this.context.testEventData.translatedText.firstLanguage
                    : "My first language is:"
                }
                id="testTakerEnglish"
                fieldType="dropdown"
                addSelectOneOption
                value={this.state.testTakerEnglish}
                options={languageOptions}
                handleInput={this.handleInput}
              />
              <Field
                label={
                  this.context.testEventData.translatedText
                    ? this.context.testEventData.translatedText.industry
                    : "Which industry best describes your current or most recent job?"
                }
                id="testTakerIndustry"
                fieldType="dropdown"
                addSelectOneOption
                value={this.state.testTakerIndustry}
                options={industryOptions}
                handleInput={this.handleInput}
                noColon={isArabic}
              />
            </div>
          ) : null}
          {this.context.testEventData.customTerms ? (
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.context.testEventData.customTerms.terms
                }}
              />
              <div>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal"
                  }}
                >
                  <input
                    type="checkbox"
                    id="agreeToTerms"
                    checked={this.state.agreeToTerms}
                    onChange={this.handleInput}
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.context.testEventData.customTerms.agree
                    }}
                  />
                </label>
              </div>
            </div>
          ) : (
            <div>
              <label
                className="tc-label"
                data-custom={isCustomTermsAndConditionsStyles()}
              >
                <div className="tc-checkbox">
                  <input
                    type="checkbox"
                    id="agreeToTerms"
                    checked={this.state.agreeToTerms}
                    onChange={this.handleInput}
                  />
                </div>
                {this.context.testEventData.translatedText ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        this.context.testEventData.translatedText.acceptTerms
                    }}
                  />
                ) : (
                  <p>
                    I accept and agree to the{" "}
                    <a
                      href="https://www.ondemandassessment.com/home/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.ondemandassessment.com/home/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Statement
                    </a>
                  </p>
                )}
              </label>
            </div>
          )}
          <Row style={{ direction: "ltr" }}>
            <Col
              xl={{ span: 6, offset: 3 }}
              lg={{ span: 6, offset: 3 }}
              md={{ span: 8, offset: 2 }}
              sm={12}
            >
              <Button
                block={true}
                variant="primary"
                id="verify-form-submit-button"
                onClick={this.handleSubmit}
              >
                {this.context.testEventData.translatedText
                  ? this.context.testEventData.translatedText.continueButton
                  : "Continue"}{" "}
                <FaArrowRight />
              </Button>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <Row>
          <Col className="spinner-container">
            <div className="spinner text-center">
              <FadeLoader height={15} color="#425CC7" />
            </div>
          </Col>
        </Row>
      );
    }
  }
}
