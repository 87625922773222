export function loadChatWidget() {
  const script = document.createElement("script");
  // The script filename here is from the build output of the `candidate-support-widget-fe` project.
  // use the develop url for local development
  // script.src = "https://dev.ondemandassessment.com/chatbot-B54AGMTV.js";
  script.src = "/chatbot-B54AGMTV.js";
  script.defer = true;

  const body = document.getElementsByTagName("body")[0];
  body.appendChild(script);

  const widget = document.createElement("criteria-candidate-support-widget");
  widget.id = "criteria-candidate-support-widget";
  body.appendChild(widget);
}
