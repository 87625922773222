import { useContext, useEffect, useState } from "react";
import { EventData, EventDataWithLockout } from "../Interfaces/EventData";
import { Lockout } from "../Interfaces/Lockout";
import { TokenContext } from "../Providers/TokenProvider";
import { apiURL, cxURL, sskowk } from "../utils/constants";
import Encryption from "../utils/Encryption";
import { ErrorMessage } from "../utils/Reference/ErrorMessage";
import { Hook } from "../utils/Reference/Hook";
import { isEventExpired } from "../utils/shared";

type GetDataProps = {
  eventId: string | null;
  assessment: string;
};

type GetDataResult = {
  data: EventDataWithLockout | null;
  isLoading: boolean;
  error: Error | null;
};

const useGetData: Hook<GetDataProps, GetDataResult> = ({
  eventId,
  assessment
}) => {
  const { returnUrl } = useContext(TokenContext);
  const [data, setData] = useState<GetDataResult["data"]>(null);
  const [error, setError] = useState<GetDataResult["error"]>(null);

  useEffect(() => {
    async function getData() {
      try {
        if (!eventId) {
          throw new Error(ErrorMessage.EVENT_ID_NOT_PROVIDED);
        }
        const [eventResponse, lockoutResponse] = await Promise.all([
          fetch(`${apiURL}/event/${eventId}`),
          fetch(`${cxURL}/e/${eventId}/lockouts?assessment=${assessment}`, {
            method: "GET",
            cache: "no-store"
          })
        ]);
        if (!eventResponse.ok || !lockoutResponse.ok) {
          throw new Error(ErrorMessage.INTERNAL_SERVER_ERROR);
        }
        const data = (await eventResponse.json()) as { data: EventData };
        if (!("data" in data)) {
          throw new Error(ErrorMessage.INTERNAL_SERVER_ERROR);
        }
        const encryption = new Encryption();
        const decryptedData = encryption.decrypt(data.data, sskowk);
        const parsedData = JSON.parse(decryptedData) as EventData;
        const lockoutData = ((await lockoutResponse.json()) as Lockout[])[0];

        if (
          lockoutData.isLockout ||
          isEventExpired(parsedData.eventExpirationDateUtc)
        ) {
          const lockoutLocation = new URL(returnUrl);
          window.location.replace(
            lockoutLocation.origin + lockoutLocation.pathname
          );
        } else {
          setData(() => ({
            ...parsedData,
            lockout: lockoutData
          }));
        }
      } catch (e) {
        console.error(e);
        e instanceof Error
          ? setError(e)
          : setError(new Error(ErrorMessage.INTERNAL_SERVER_ERROR));
      }
    }
    getData();
  }, [eventId, assessment, returnUrl]);

  return {
    data,
    isLoading: !data,
    error
  };
};

export default useGetData;
