export const useShowChat = () => {
  // Show the chat widget so we can use it from the network error.
  const chatWidget = document.getElementById(
    "criteria-candidate-support-widget"
  );
  if (chatWidget && chatWidget instanceof HTMLElement) {
    chatWidget.hidden = false;
    chatWidget.style.position = "absolute";
    chatWidget.style.bottom = "20";
    chatWidget.style.right = "20";
    chatWidget.style.zIndex = "999";
  }
};
