import React, { Component } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { TestEventContext } from "../../Contexts/TestEventContext";
import { TenkeyTestAnswerData } from "../../Interfaces/AnswerData";
import { nodeApiURL } from "../../utils/constants";

interface Props {
  testData: any;
  subTestId: string;
  endSubTest: () => void;
}
interface State {
  typedInput: string;
  startTime: number;
  error: boolean;
}

export default class TenKeyTests extends Component<Props, State> {
  state = { typedInput: "", startTime: 0, error: false };

  // define the context type so that we can use this.context throughout the class
  static readonly contextType = TestEventContext;

  componentDidMount() {
    // we need to disable the pasting functionality to guard against improper test results
    const typingTextArea: any = document.getElementById("ten-key-textarea");
    if (typingTextArea !== null) {
      typingTextArea.onpaste = this.handlePaste;
      typingTextArea.ondrop = this.handlePaste;
    }
  }

  handleTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // if the start time has not been calculated yet (on first key press), do so now
    if (this.state.startTime === 0) {
      this.setState({ startTime: Date.now() });
    }
    this.setState({ typedInput: event.target.value }, () => {
      if (this.state.error) {
        this.setState({ error: false });
      }
    });
  };

  validateAnswers = (): boolean => {
    return this.state.typedInput.split("\n").length >= 20;
  };

  handleFinishButton = () => {
    // calculate time to complete (in seconds);
    const finishTime: number = Date.now();
    const timeTaken =
      this.state.startTime !== 0
        ? (+finishTime - +this.state.startTime) / 1000
        : 0;

    // create answerData object and send that data to redis, and then end the subTest
    const answerData: TenkeyTestAnswerData = {
      testEventId: this.context.testEventId,
      subTestId: this.props.subTestId,
      typedInput: this.state.typedInput,
      timeTaken
    };
    if (this.validateAnswers()) {
      this.sendTenKeyDataToRedis(answerData, this.context.token);
    } else {
      this.setState({ error: true });
    }
  };

  sendTenKeyDataToRedis = async (
    answerData: TenkeyTestAnswerData,
    token: string
  ): Promise<boolean> => {
    try {
      const response = await fetch(`${nodeApiURL}/sendTenKeyTestAnswer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        body: JSON.stringify(answerData)
      });
      const responseData = await response.json();
      if (responseData) {
        this.props.endSubTest();
      }
    } catch (error) {
      console.log("error", error);
    }
    return true;
  };

  handlePaste = () => {
    return false;
  };

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <h5 className="typing-test-header">Criteria 10-Key Test</h5>
            <p>
              Copy each entry in the corresponding space on the right. Press
              enter to proceed to the next entry, and be sure to click on the
              "Finish" button on the right when you are finished. The time will
              not stop until you click the "Finish" button.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xl={4} lg={4} md={4} sm={4} className="no-select">
            <h6 style={{ marginBottom: "18px" }}>Data to Enter</h6>
            <div className="ten-key-prompts ten-key-odd-row">1957 [enter]</div>
            <div className="ten-key-prompts">2068.12 [enter]</div>
            <div className="ten-key-prompts ten-key-odd-row">95164 [enter]</div>
            <div className="ten-key-prompts">32.025 [enter]</div>
            <div className="ten-key-prompts ten-key-odd-row">
              4125.93 [enter]
            </div>
            <div className="ten-key-prompts">7564.2 [enter]</div>
            <div className="ten-key-prompts ten-key-odd-row">60284 [enter]</div>
            <div className="ten-key-prompts">16754.42 [enter]</div>
            <div className="ten-key-prompts ten-key-odd-row">
              89425.12 [enter]
            </div>
            <div className="ten-key-prompts">30598 [enter]</div>

            <div className="ten-key-prompts ten-key-odd-row">
              1809.32+7895.43 [enter]
            </div>
            <div className="ten-key-prompts">456.1*65647.23 [enter]</div>
            <div className="ten-key-prompts ten-key-odd-row">
              9851.01/654.32 [enter]
            </div>
            <div className="ten-key-prompts">5456.98-6542.1 [enter]</div>
            <div className="ten-key-prompts ten-key-odd-row">
              2015.47*8754.07 [enter]
            </div>
            <div className="ten-key-prompts">323.29/10.002 [enter]</div>
            <div className="ten-key-prompts ten-key-odd-row">
              8574.63+6125.88 [enter]
            </div>
            <div className="ten-key-prompts">1203.19-206.36 [enter]</div>
            <div className="ten-key-prompts ten-key-odd-row">
              8146.62*5743.21 [enter]
            </div>
            <div className="ten-key-prompts">
              9137.02/6041.57 <strong>[CLICK FINISH]</strong>
            </div>
          </Col>
          <Col xl={4} lg={4} md={4} sm={4}>
            <h6>Enter Data Below</h6>
            <textarea
              id="ten-key-textarea"
              name="ten-key-textarea"
              onChange={this.handleTextArea}
              value={this.state.typedInput}
            />
          </Col>
          <Col
            xl={4}
            lg={4}
            md={4}
            sm={4}
            className="no-select"
            id="ten-key-finish-button-container"
          >
            <input
              className="button-large button-shadow"
              id="ten-key-finish-button"
              type="button"
              value="Finish"
              onClick={this.handleFinishButton}
            />
          </Col>
        </Row>
        {this.state.error ? (
          <Row>
            <Col className="text-center">
              <Alert variant="danger">
                Please complete all 20 typed rows before finishing.
              </Alert>
            </Col>
          </Row>
        ) : null}
      </Container>
    );
  }
}
